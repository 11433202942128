import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { Button, Paragraph } from "@reamrd/styledoubler";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // default theme
import { match } from "ts-pattern";

Amplify.configure(config);

// ReactDOM.render(
//   <AmplifyProvider>
//     <Authenticator loginMechanisms={["email"]}>
//       {({ signOut, user }) => {
//         const cognitoUser = user as any;

//         if (cognitoUser.challengeName === "MFA_SETUP") {
//           return (
//             <>
//               <div className="w-full h-full flex flex-col items-center justify-center">
//                 <Paragraph
//                   variant="P4"
//                   text="Welcome on board! You will now be taken to a login page - use your new credentials to log in and proceed to Multi Factor Authentication setup"
//                 />
//                 <div className="mt-4">
//                   <Button
//                     text="Proceed"
//                     onClick={() => window.location.reload()}
//                   />
//                 </div>
//               </div>
//             </>
//           );
//         }

//         return (
//           <React.StrictMode>
//             <RecoilRoot>
//               <BrowserRouter>
//                 <App signOut={signOut} user={user} />
//               </BrowserRouter>
//             </RecoilRoot>
//           </React.StrictMode>
//         );
//       }}
//     </Authenticator>
//   </AmplifyProvider>,

//   document.getElementById("root")
// );

const RenderIframe = () => {
  const { href, search } = window.location;

  if (!search) {
    return <></>;
  }

  return (
    <iframe
      className="w-full h-full"
      src={match(true)
        .with(href.includes("brands."), () =>
          href.replace("brands.", "managers.")
        )
        .otherwise(() => href.replace("3001", "3000"))}
    />
  );
};

ReactDOM.render(
  <RenderIframe />,

  document.getElementById("root")
);
