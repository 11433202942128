// =========== Production Environment =================================

const awsmobileProd = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:e96eabe4-3dc1-4eec-a1a7-f44228853734",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_23L6vLwSg",
  aws_user_pools_web_client_id: "79ib9vnnin203krpe2iofuqcrc",
  oauth: {},
  aws_cognito_login_mechanisms: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

// =========== DEV Environment ==================================

const awsmobileDev = {
  aws_project_region: "eu-north-1",
  aws_cognito_region: "eu-north-1",
  aws_user_pools_id: "eu-north-1_SY5j4Uqk3",
  aws_user_pools_web_client_id: "5aunusmeav5utqb54mdvkrkdh0",
  aws_cognito_identity_pool_id:
    "eu-north-1:4925b85b-f03c-47f3-abe0-99e77a20bb9f",
  aws_user_files_s3_bucket_region: "eu-north-1",
};

const currentConfig =
  process.env.REACT_APP_NODE_ENV === "development"
    ? awsmobileDev
    : awsmobileProd;

export default currentConfig;
